@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$sidebar-bg-color: #ffffff;
$submenu-bg-color: rgba(207, 200, 200, 0.05);
$submenu-bg-color-collapsed: rgba(#351106, 0.95);
$sidebar-color: #4a1501;
$highlight-color: #fe4b07;
$icon-bg-color: #FEA382;
$icon-size: 30px;

@import '~react-pro-sidebar/dist/scss/styles.scss';
body {
  margin: 0;
  height: 100vh;
  color: #000000;
  font-family: 'Roboto', sans-serif;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  position: fixed;
  
  .btn-toggle {
    cursor: pointer;
    width: 35px;
    height: 35px;
    background: #353535;
    color: #fff;
    text-align: center;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    display: none;
  }

  .sidebar-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .sidebar-btn {
      transition: width 0.3s;
      width: 150px;
      padding: 1px 15px;
      color: #4a1501;
      text-decoration: none;
      margin: 0 auto;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-overflow: ellipsis;
      overflow: hidden;
      span {
        margin-left: 5px;
        font-size: 13px;
      }
      &:hover {
        color: $highlight-color;
      }
    }
  }

  .collapsed {
    .sidebar-btn {
      display: inline-block;
      line-height: 40px;
      padding: 10px 15px;
    }
  }

  .block {
    display: flex;
    margin-bottom: 24px;
    font-size: 14px;
    color: #545454;
    > span {
      margin-left: 10px;
    }
  }

  @media (max-width: 767.98px) {
    overflow-x: hidden;
    header {
      h1 {
        font-size: 24px;
      }
    }
  }
  @media (max-width: $breakpoint-md) {
    .btn-toggle {
      display: flex;
    }

    &.rtl {
      .btn-toggle {
        margin-left: auto;
      }
    }
  }
}

.toggle-button{
  padding: 0px !important;
  width: 35px !important;
  float: right;
  margin-right: -30px !important;
  margin-top: 5px !important;
}

.toggle-collapsed-button{
  margin: 10px 17px 10px 17px !important;
}

.pro-inner-item{
  padding: 8px 15px 8px 15px !important;
}